import React, { useEffect } from "react";
import "./ProjectDetails.css";
import Header from "../Home/Header";
import pic from "../Assets/images/jpg/67-a.jpg";
import "../Home/Map.css";
import pic2 from "../Assets/images/jpg/73-b2.jpg";
import sideLogo from "../Assets/images/web_2024-39.svg";
import checkIcon from "../Assets/images/web_2024-10.svg";
import pdfIcon from "../Assets/images/web_2024-21.svg";
import plusIcon from "../Assets/images/web_2024-31.svg";
import minusIcon from "../Assets/images/web_2024-32.svg";
import designpic from "../Assets/images/web_2024-33.svg";

import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { Swiper, SwiperSlide } from "swiper/react";
import { useRef, useState } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Footer from "../Home/Footer";
import axios from "axios";
import instagram from "../Assets/images/web_2024-22.svg";
import whatsApp from "../Assets/images/web_2024-23.svg";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ic1 from "../Assets/images/available_new.svg";
import ic2 from "../Assets/images/sold_new-1.svg";
import ic3 from "../Assets/images/soon_new.svg";
import ic4 from "../Assets/images/soon_new copy.svg";
import YouTube from "react-youtube";
const mapStyles = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [{ saturation: -100 }, { lightness: 20 }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ lightness: 50 }, { visibility: "simplified" }],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ lightness: 30 }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#f0f0f0" }], // Light gray color for water
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [{ visibility: "simplified" }],
  },
];
// Function to extract YouTube video ID from URL
const getYoutubeVideoId = (url) => {
  // Regex pattern to match YouTube video URLs
  const youtubePattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})$/;
  const match = url.match(youtubePattern);
  // If match found, return the video ID, otherwise return null
  return match ? match[1] : null;
};


const ProjectsDetails = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [websiteData, setWebsiteData] = useState("");

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchProjectDetails();
    fetchWebsiteData();
  }, []);

  const fetchProjectDetails = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/project/" + id)
      .then((res) => {
        setProjectDetails(res.data);
      });

    setLoading(true);
  };
  const fetchWebsiteData = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/website-data")
      .then((res) => {
        setWebsiteData(res.data.data);
      });
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const center = {
    lat: projectDetails.data
      ? parseFloat(projectDetails.data.latitude)
      : 24.7136, // Latitude for Riyadh, Saudi Arabia
    lng: projectDetails.data
      ? parseFloat(projectDetails.data.longitude)
      : 46.6753, // Longitude for Riyadh, Saudi Arabia
  };

  const iconUrls = {
    1: ic1,
    2: ic2,
    3: ic3,
    4: ic4,
  };
  const [selected, setSelected] = useState(null);
  // Function to extract YouTube video ID from URL
 

  return (
    <div className="main-div ">
      {loading ? (
        <div>
          {projectDetails && (
            <div key={projectDetails.data.id}>
              <div className={` ${isScrolled ? "noback" : "back"}`}></div>
              <Header />
              <div className="classs">
                <img
                  className="mainimgg"
                  src={projectDetails.data.main_image}
                ></img>
              </div>
              <div className="back2"></div>
              <div className="who-us container">
                <p className="whoustitle">{projectDetails.data.name}</p>
              </div>
              <div className="break "></div>

              <div className="sectionn mb-4 ">
                <div className="sidebar mt-3 ms-5">
                  <img className="sidelogo mt-5" src={sideLogo} alt="" />
                  <p> إحـجـــز الأن</p>
                  <a
                    className="phone-num "
                    href="tel:{websiteData.res_num1}"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="">{websiteData.res_num2}</span>
                  </a>
                  <a
                    className="phone-num "
                    href="tel:{websiteData.res_num1}"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="">{websiteData.res_num1}</span>
                  </a>
                  <div className="d-flex flex-row">
                    <a
                      className="ms-3"
                      href={`http://wa.me/${websiteData.whats_app_num}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="whatsapp-sidebar    "
                        src={whatsApp}
                        alt=""
                      />
                    </a>
                    <a
                      className=""
                      href={websiteData.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="instagram-sidebar"
                        src={instagram}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="sub2   mt-3">
                  <div className="swip">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      {projectDetails.data.alt_images.map(
                        (swiperimage, indx) => (
                          <SwiperSlide>
                            <img className="stages-img" src={swiperimage} />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={5}
                      slidesPerView={projectDetails.data.alt_images.length}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      {projectDetails.data.alt_images.map(
                        (swiper2image, indx2) => (
                          <SwiperSlide>
                            <img className="stages-img" src={swiper2image} />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>
                  <div className="all container  ">
                    <div className=" details">
                      <div className="right">
                        <p className="details-title">المساحة الكلية</p>
                        <p className="details-subtitle">
                          <span>{projectDetails.data.area} </span> متر مربع
                        </p>
                      </div>
                      <div className="left">
                        <p className="details-title"> عـــدد الــواحــدات</p>
                        <p className="details-subtitle">
                          <span>{projectDetails.data.units_no}</span> وحدة
                        </p>
                      </div>
                    </div>
                    <div className="description ">
                      <p className="description-title"> الوصف</p>
                      <p className="description-subtitle">
                        {projectDetails.data.description}
                      </p>
                    </div>
                    <p className="description-title"> مميزات المشروع</p>
                    <div className="advantages ">
                      {projectDetails.data.features.map((feature, i) => (
                        <div className="adv">
                          <img className="checkicon" src={checkIcon} alt="" />
                          <span>{feature.name} </span>
                        </div>
                      ))}
                    </div>

                    <p className="description-title"> برشور المشروع</p>
                    <div className="pdf d-flex flex-row align-items-center">
                      <a
                        className="pdflink "
                        href={projectDetails.data.brochure}
                        download="UnionDiamond.pdf"
                      >
                        <img className="pdfimage ms-3" src={pdfIcon} alt="" />
                        {projectDetails.data.name}
                      </a>
                    </div>
                    <p className="description-title"> مساحات وتخطيط الوحدات </p>
                    {projectDetails.data.plans.map((plan, dx) => (
                      <div>
                        <div
                          onClick={() => {
                            handleItemClick(plan.id);
                          }}
                          className="design  "
                          key={plan.id}
                        >
                          <img
                            className={`${
                              selectedItemId === plan.id ? "hide" : "plusicon"
                            }`}
                            src={plusIcon}
                            alt=""
                          />

                          <img
                            className={`${
                              selectedItemId === plan.id ? "plusicon" : "hide"
                            }`}
                            src={minusIcon}
                            alt=""
                          />
                          <p className="area">{plan.title}</p>
                        </div>
                        {selectedItemId === plan.id && (
                          <div className="planimg-div">
                            <img className="planimg" src={plan.image} />
                          </div>
                        )}
                      </div>
                    ))}

                    <p className="description-title"> فيديو المشروع </p>
                    <div className="vid">
                        <YouTube className="youtube"
                          videoId={getYoutubeVideoId(projectDetails.data.video)}
                        />
                    </div>
                    <p className="description-title"> موقع المشروع </p>
                    <div className="mapp">
                      <LoadScript googleMapsApiKey="AIzaSyDQ-KjfF-16mlmAqTIC5TiwQ3wVn5ZcabE">
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={10}
                          options={{ styles: mapStyles }}
                        >
                          <Marker
                            key={projectDetails.data.id}
                            position={{
                              lat: parseFloat(projectDetails.data.latitude),
                              lng: parseFloat(projectDetails.data.longitude),
                            }}
                            icon={iconUrls[projectDetails.data.status]} // Map integer status to icon URL
                            onClick={() => setSelected(projectDetails.data)}
                          />

                          {selected && (
                            <InfoWindow
                              position={{
                                lat: parseFloat(selected.latitude),
                                lng: parseFloat(selected.longitude),
                              }}
                              onCloseClick={() => setSelected(null)}
                            >
                              <div>
                                <p className="pin-desc-title">
                                  {selected.name}
                                </p>
                                <img
                                  src={selected.main_image}
                                  alt="Location"
                                  style={{ width: "150px", height: "100px" }}
                                />
                              </div>
                            </InfoWindow>
                          )}
                        </GoogleMap>
                      </LoadScript>
                      {/* <Map
                        lat={projectDetails.data.latitude}
                        long={projectDetails.data.longitude}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          )}
        </div>
      ) : (
        <div className="overlay">
          {" "}
          <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="secondary" />
          <Spinner animation="grow" variant="dark" />
        </div>
      )}
    </div>
  );
};

export default ProjectsDetails;
