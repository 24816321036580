import React, { useState, useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import instagram from "../Assets/images/web_2024-22.svg";
import whatsApp from "../Assets/images/web_2024-23.svg";
import axios from "axios";
import "./Photos.css";
import pic from "../Assets/images/jpg/67-a.jpg";
import pic2 from "../Assets/images/jpg/73-b2.jpg";
import sideLogo from "../Assets/images/web_2024-39.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Stages.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Spinner from "react-bootstrap/Spinner";


const Stages = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [websiteData, setWebsiteData] = useState("");
  const [picturesData, setPicturesData] = useState([]);
  const [thumbsSwipers, setThumbsSwipers] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchWebsiteData();
    fetchpicsData();

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchWebsiteData = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/website-data")
      .then((res) => {
        setWebsiteData(res.data.data);
      });
  };

  const fetchpicsData = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/project_stages")
      .then((res) => {
        setPicturesData(res.data.data);
      });
    setLoading(true);
    
  };

  return (
    
    
    <div className="">
      <div className={` ${isScrolled ? "noback" : "back"}`}></div>
      <Header />
      <div className="classs">
        <img className="mainimg" src={pic}></img>
      </div>
      <div className="back2"></div>
      <div className="who-us container">
        <h1> مراحل البناء</h1>
      </div>
      <div className="break"></div>

      {loading ?
        <div className="sub">
          {picturesData &&
            picturesData.map((pdata, indexx) => (
              <div className="sub" key={indexx}>
                <div className="sectionn  ">
                  <div className="sidebar mt-3">
                    <img className="sidelogo mt-5" src={sideLogo} alt="" />
                    <p> إحـجـــز الأن</p>
                    <a
                      className="phone-num "
                      href="tel:{websiteData.res_num1}"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="">{websiteData.res_num2}</span>
                    </a>
                    <a
                      className="phone-num "
                      href="tel:{websiteData.res_num1}"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="">{websiteData.res_num1}</span>
                    </a>
                    <div className="d-flex flex-row">
                      <a
                        className="ms-3"
                        href={`http://wa.me/${websiteData.whats_app_num}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="whatsapp-sidebar    "
                          src={whatsApp}
                          alt=""
                        />
                      </a>
                      <a
                        className=""
                        href={websiteData.instagram_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="instagram-sidebar"
                          src={instagram}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="sub">
                    <div className=" project-title pe-5 ps-5 mt-3 mb-3">
                      <p className="">{pdata.name}</p>
                      <div className="">
                        <img className="titlelogoimg" src={pdata.logo} alt="" />
                      </div>
                    </div>
                    <div className="swiper3">
                      <Swiper
                        style={{
                          "--swiper-navigation-color": "#fff",
                          "--swiper-pagination-color": "#fff",
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwipers[indexx] }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                      >
                        {pdata &&
                          pdata.stages_images.map((swiperimage, indx) => (
                            <SwiperSlide key={indx}>
                              <img
                                className="stages-img"
                                src={swiperimage}
                                alt=""
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                      <Swiper
                        loop={true}
                        spaceBetween={5}
                        slidesPerView={pdata.stages_images.length}
                        freeMode={true}
                        watchSlidesProgress={true}
                        onSwiper={(swiper) =>
                          setThumbsSwipers((prev) => [...prev, swiper])
                        }
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                      >
                        {pdata &&
                          pdata.stages_images.map((swiper2image, indx) => (
                            <SwiperSlide key={indx}>
                              <img
                                className="stages-img"
                                src={swiper2image}
                                alt=""
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        : <div className="overlay">    <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="secondary" />
          <Spinner animation="grow" variant="dark" /></div>}
   

      <Footer />
    </div>
  );
};

export default Stages;
