import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Proj.css";
import badge from "../Assets/images/web_2024-25.svg";
import { Link } from "react-router-dom";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';



const Proj = () => {
  const [projectss, setProjectss] = useState([]);
 const [hoveredItem, setHoveredItem] = useState(-1);
 const [hovered, setHovered] = useState(false);
  const [loading , setLoading] = useState(false);


  useEffect(() => {
    fetchprojects();
  }, [])
  
  const fetchprojects = async () => {
    await axios.get("https://back-end.uniondiamondco.com/api/projects").
      then(
      (res )=> {
        setProjectss(res.data.data);
      });setLoading(true);
  }

  const handleMouseEnter = (e,index) => {
    setHoveredItem(index);
    setHovered(true);
  };
    const handleMouseLeave = () => {
      setHoveredItem(-1);
      setHovered(false);
    };

  

  return (
    <div className=" mt-2  proj-section  ">
   {loading? <div> 
      <div className="  contain    ">
        {projectss &&
          projectss.map((projct, index) => (
            <div
              className="project" // Apply 'hovered' class if the div is being hovered over
              onMouseEnter={(e) => handleMouseEnter(e, index)}
              onMouseLeave={handleMouseLeave}
              key={index}
            >
              <div className="imageparent ">
                {projct.is_featured === "1" && (
                  <div className="badge">
                    <span className="special">مـمـيـز</span>

                    <img className="bdge-img" src={badge} alt="" />
                  </div>
                )}
                <img className="projectimg" src={projct.main_image} alt="" />
                {hovered && (
                  <Link
                    className={` ${
                      index === hoveredItem ? "detailsbutton" : "hidden"
                    }`}
                    to={`/projectdetails/${projct.id}`}
                  >
                    <button type="button" className="btn ">
                      تفاصيل المشروع
                    </button>
                  </Link>
                )}
              </div>
              <p className="text1">{projct.name}</p>
              <p className="text2">{projct.description}</p>
              <p className="text3"> {projct.type}</p>
            </div>
          ))}
      </div>
      
    </div>:<div className="overlay">    <Spinner animation="grow" variant="info" />
      <Spinner animation="grow" variant="secondary" />
      <Spinner animation="grow" variant="dark" /></div>}
    </div>
  );
};

export default Proj;
