import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import "./Menu.css";
import instagram from "../Assets/images/web_2024-22.svg";
import whatsApp from "../Assets/images/web_2024-23.svg";
import phoneIcon from "../Assets/images/web_2024-29.svg";
import axios from 'axios';




const Menu = () => {
 
  return (
    <div className="menuu">
      <Link  className="item" to="/">
        الـرئيسـيـة
      </Link>

      <Link className="item" to="/about">
        مـن نـحــن
      </Link>

      <Link className="item" to="/projects">
        مشـاريعـنـا
      </Link>

      <Link className="item" to="/stages">
        مراحل البناء
      </Link>
{/* 
      <Link className="item" to="/photos">
        الــصـــــور
      </Link> */}

      <Link className="item lst" to="/contactus">
        إتـصـل بـنـا
      </Link>
      {/* <div className="d-flex flex-row  mb-3 align-items-center justify-content-center">
        <a
          className="contact d-flex flex-row mt-2 align-items-center justify-content-center"
          href={`tel:${websiteData.res_num1}`}
          target="_blank"
        >
          <span className="menu-num "> {websiteData.res_num1}</span>
          <img className="phoneicon   " src={phoneIcon} alt="" />
        </a>

        <a
          className=""
          href={`http://wa.me/${websiteData.whats_app_num}`}
          target="_blank"
        >
          <img className="whatsappp    " src={whatsApp} alt="" />
        </a>
        <a className="" href={websiteData.instagram_link} target="_blank">
          <img className="instagramm " src={instagram} alt="" />
        </a>
      </div> */}
    </div>
  );
}

export default Menu;