import React, { useEffect, useState } from "react";
import "./About.css";
import Header from "../Home/Header";
import "../Home/Header.css";
import "../Home/Hero.css";
import pic from "../Assets/images/jpg/67-a.jpg";
import "../Home/Menu";
import visionIcon from "../Assets/images/web_2024-15.svg";
import msgIcon from "../Assets/images/web_2024-16.svg";
import valuesIcon from "../Assets/images/web_2024-17.svg";
import oneIcon from "../Assets/images/web_2024-05.svg";
import twoIcon from "../Assets/images/web_2024-09.svg";
import threeIcon from "../Assets/images/web_2024-08.svg";
import fourIcon from "../Assets/images/web_2024-07.svg";
import fiveIcon from "../Assets/images/web_2024-06.svg";
import sloganIcon from "../Assets/images/web_2024-18.svg";
import presidentIcon from "../Assets/images/web_2024-19.svg";
import Footer from "../Home/Footer";

const About = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={` ${isScrolled ? "noback" : "back"}`}></div>
      <Header />
      <div className="classs">
        <img className="mainimg" src={pic}></img>
      </div>
      <div className="back2"></div>
      <div className="who-us container">
        <p className="whoustitle"> مـن نـحــن</p>
      </div>
      <div className="break"></div>
      <div className="introduction container">
        <p>
          اتحاد الماسة للمقاولات تم تأسيسها في عام 2004 لتكون ضمن الشركات
          الوطنية الرائدة في مجال التطوير العقاري والاعمال الانشائية بمعايير
          جودة عالية وتقنيات حديثة تلبى كافة رغبات وطلبات السوف المحلى
        </p>
      </div>
      <div className="detailss container mt-5">
        <div className="p1">
          <div className="vision  ">
            <div className="vision-title">
              <div className="vertical-line"></div>
              <p className="title">الرؤيـة</p>
              <img className="vision-icon " src={visionIcon} alt="" />
            </div>
            <p className="text">
              التخطيط الناجح والتنفيذ الدقيق والأداء المبتكر والجودة العالية
              والمصداقية الكبيرة، هي معايير عملنا التي تعزز ريادتنا للخدمات
              المتكاملة في مجال التطوير العقاري.
            </p>
          </div>
          <div className="vision ">
            <div className="vision-title">
              <div className="vertical-line"></div>
              <p className="title">الرسالة</p>
              <img className="vision-icon" src={msgIcon} alt="" />
            </div>
            <p className="text">
              من أكبر الشركات التي تعمل بنشاط التطوير العقاري وان نقوم بناء مسكن
              عصري متميز يلبى جميع احتياجات الاسرة بأعلى معايير من الجودة في
              البناء والتشييد حسب الأنظمة الحديثة
            </p>
          </div>
        </div>

        <div className="vision ">
          <div className="vision-title">
            <div className="vertical-line"></div>
            <p className="title">قيمنا</p>
            <img className="vision-icon" src={valuesIcon} alt="" />
          </div>
          <div className="logos">
            <div className="one">
              <img className="icon" src={oneIcon} alt="" />
              <p>التعاون والشراكة</p>
            </div>
            <div className="two">
              <img className="icon" src={twoIcon} alt="" />
              <p>الكفاءة والجودة</p>
            </div>
            <div className="three">
              <img className="icon" src={threeIcon} alt="" />
              <p> الـتـحـــــدي</p>
            </div>
            <div className="four">
              <img className="icon" src={fourIcon} alt="" />
              <p> الإبــــداع</p>
            </div>
            <div className="five">
              <img className="icon" src={fiveIcon} alt="" />
              <p> السـلامــة</p>
            </div>
          </div>
        </div>
        <div className="vision ">
          <div className="vision-title">
            <div className="vertical-line"></div>
            <p className="title">شعارنا</p>
            <img className="vision-icon" src={sloganIcon} alt="" />
          </div>
          <p>
            التميز <br />
            الجودة
            <br />
            المصداقية
          </p>
        </div>
        <div className="vision">
          <div className="vision-title">
            <div className="vertical-line"></div>
            <p className="title">رئيس مجلس الإدارة</p>
            <img className="vision-icon" src={presidentIcon} alt="" />
          </div>
          <p className="text">
            بما اننا نعيش في وطن يذخر بالتقدم والابداع وايمانا منا بان سبل
            النجاح تكمن في فهم وتطبيق المعرفة والأفكار الجديدة وتماشيا مع رؤية
            المملكة 2030 أردنا ان نكون جزء فعال ومساهم في تلك الرؤية وتبين لنا
            ان قطاع المقاولات والتشييد والبناء يعتبر من أحد ابرز دعائم التنمية
            الاقتصادية وبفضل الله قمنا بتأسيس شركتنا كشركة وطنية تساهم في
            الارتقاء في هذا المجال ووضع بصمة متميزة ساعدنا في ذلك الكوادر
            الماهرة ذات الخبرة والثقة الغالية من عملاءنا الكرام.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
